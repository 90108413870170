import React from 'react'
import ReactDOM from 'react-dom/client'
import '@fontsource/dm-sans'
import '@fontsource/dm-sans/500.css'
import '@fontsource/dm-sans/700.css'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './contexts/AuthContext'
import { BrowserRouter } from 'react-router-dom'
import { SharedDataProvider } from './contexts/SharedDataContext'
// import Gleap from 'gleap'
import * as Sentry from '@sentry/react'
import ErrorFallback from './components/errors/ErrorFallback'

// Gleap.setEnvironment(process.env.REACT_APP_GLEAP_ENV)
// // Please make sure to call this method only once!
// Gleap.initialize(process.env.REACT_APP_GLEAP_KEY)

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://aff01339b3ace1ebd26335e581844cab@o4508135697874944.ingest.us.sentry.io/4508135702003712',
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_VERSION,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.tournaments\.stringr\.pro/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <SharedDataProvider>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </SharedDataProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
